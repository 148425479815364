<template>
  <div>
    <b-sidebar
      id="view-materials-details-manu"
      ref="modal"
      header-class="headerModalToggle"
      right
      shadow
      size="l"
      width="1000px"
      :title="`${orderMovementNumber} - ${$t('MaterialVariants')}`"
      no-close-on-esc
      @close="onCancelToggle"
    >
      <template #title>
        <strong style="color: #101828;">{{ $t('orderMovementNumber') }} - {{ orderMovementNumber }} : {{ $t('ShopToManu') }}</strong>
      </template>
      <template
        v-slot:header-close
      >
        <button
          style="color: #98A2B3;padding-left: 0px; padding-right: 0px;height: 60px;"
          class="fa fa-close"
          @click="onCancelToggle"
        />
      </template>
      <div
        v-if="selectedOrders.length > 0"
        style="padding:10px 20px 10px 20px;"
      >
        <button
          v-if="status !== 'NotAccepted'"
          v-b-modal.assign-order-teams
          class="button"
          style="width:30%"
        >
          {{ $t('AssignTeamAllOrder') }} {{ selectedOrders.length }} {{ $t('Orders') }}
        </button>
      </div>
      <div
        style="padding:10px 20px 10px 20px;"
      >
        <input
          type="checkbox"
          :checked="selectAll"
          :disabled="status == 'NotAccepted'"
          @change="toggleSelectAll"
        >
        {{ $t('SelectAll') }}
      </div>
      <div
        v-if="status == 'NotAccepted'"
        style="padding: 20px;margin-bottom:0px;"
      >
        <p style="color:red">
          {{ $t('CannotAssign') }}
        </p>
      </div>
      <div
        v-for="(item,index) in computedMaterials"
        :key="index"
        style="padding:20px;"
      >
        <div style="display: flex;justify-content: space-between;align-items: baseline;">
          <p style="font-weight: 600;margin:0px;display: flex;align-items: baseline;gap:5px;">
            <input
              type="checkbox"
              :value="item.isSelected"
              :disabled="status == 'NotAccepted'"
              :checked="isItemChecked(item)"
              @change="event => toggleOrderItem(event, index)"
            >
            <span>
              {{ $t('Order') }} - {{ item.orderNumber }} , {{ $t('ArticleName') }} - {{ item.articleNumber }}
            </span>
          </p>
          <button
            v-if="status !== 'NotAccepted'"
            v-b-modal.assign-order
            class="button"
            @click="assignTeamOrder(item)"
          >
            {{ item.assginmentTeamNameId ? item.assginmentTeamName : $t('AssignTeam') }}
          </button>
        </div>
        <table
          class="team_table"
          style="margin-top: 10px"
        >
          <thead>
            <tr>
              <th>
                {{ $t('MaterialVariantName') }}
              </th>
              <th>
                {{ $t('Unit') }}
              </th>
              <th v-if="item.length > 0 ">
                {{ $t('Length') }}
              </th>
              <th v-else>
                {{ $t('Quantity') }}
              </th>
            </tr>
          </thead>
          <tbody
            v-for="(item1,index1) in item.materialVariantInWithoutPriceStoreDtos"
            :key="index1"
          >
            <tr>
              <td>
                {{ item1.name }}
              </td>
              <td>
                {{ item1.measurementType }}
              </td>
              <td v-if="item.length > 0 ">
                {{ item1.length }}
              </td>
              <td v-else>
                {{ item1.quantity }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        style="padding:20px;"
      >
        <p style="font-weight: 600">
          {{ $t('AditionalMaterialVariant') }}
        </p>
        <table
          class="team_table"
        >
          <thead>
            <tr>
              <th>
                {{ $t('MaterialVariantName') }}
              </th>
              <th>
                {{ $t('MeasurementType') }}
              </th>
              <th>
                {{ $t('Length') }}
              </th>
              <th>
                {{ $t('Description') }}
              </th>
              <th>
                {{ $t('Notes') }}
              </th>
            </tr>
          </thead>
          <tbody
            v-for="(item,index) in getStoreManuDetails.additionalMaterialVariantsInMovement"
            :key="index"
          >
            <tr>
              <td>
                {{ item.name }}
              </td>
              <td>
                {{ item.measurementType }}
              </td>
              <td>
                {{ item.quantity }}
              </td>
              <td>
                {{ item.description }}
              </td>
              <td>
                {{ item.notes }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        style="padding:20px;"
      >
        <p style="font-weight: 600">
          {{ $t('AditionalProductsInStock') }}
        </p>
        <table
          class="team_table"
        >
          <thead>
            <tr>
              <th>
                {{ $t('ArticleNumber') }}
              </th>
              <th>
                {{ $t('Amount') }}
              </th>
              <th>
                {{ $t('Notes') }}
              </th>
              <th>
                {{ $t('AssignTeam') }}
              </th>
            </tr>
          </thead>
          <tbody
            v-for="(item,index) in getStoreManuDetails.productInStockWithNotes"
            :key="index"
          >
            <tr>
              <td>
                {{ item.articleNumber }}
              </td>
              <td>
                {{ item.amount }}
              </td>
              <td>
                {{ item.notes }}
              </td>
              <td>
                <button
                  v-if="status == 'Accepted'"
                  v-b-modal.assign-products-stock
                  class="button"
                  @click="assignTeamProducts(item)"
                >
                  {{ item.assginmentTeamNameId ? item.assginmentTeamName : $t('AssignTeam') }}
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        v-if="status == 'Accepted'"
        style="padding: 20px"
      >
        <p style="color:red">
          {{ $t('OrderMovementBack') }}
        </p>
      </div>
      <div
        class="buttonsEverywhereToggle"
        style="gap:15px"
      >
        <b-button
          v-if="status == 'NotAccepted' || status == ''"
          type="button"
          variant="none"
          class="buttonSubmit"
          @click="acceptOrder"
        >
          {{ $t('AcceptOrder') }}
        </b-button>
        <b-button
          v-if="status == 'Accepted'"
          type="button"
          variant="none"
          class="buttonSubmit"
          @click="notAcceptedOrder"
        >
          {{ $t('DontAcceptOrder') }}
        </b-button>
        <b-button
          type="button"
          variant="danger"
          class="buttonCancel"
          @click="onCancel"
        >
          {{ $t('Cancel') }}
        </b-button>
      </div>
      <AssignOrders
        :order-items="orderItems"
        :assignment-team-name-id="assginmentTeamNameId"
        @assignTeamOne="assignOneTeam"
      />
      <AssignProductsStock
        :products-stock="productsStock"
        :assignment-team-name-id="assginmentTeamNameId"
        @assignProduct="assignProducts"
      />
      <AssignOrdersTeam
        :selected-orders="selectedOrders"
        @assignTeams="assignSelectedTeams"
      />
    </b-sidebar>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import AssignOrders from './AssignOrders.vue';
import AssignOrdersTeam from './AssignOrdersTeam.vue';
import AssignProductsStock from './AssignProductsStock.vue';





export default {
  components: {
    AssignOrders,
    AssignOrdersTeam,
    AssignProductsStock,
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ['orderMovementNumber', 'status', 'orderMovementId'],
  data() {
    return {
      selectAll: false,
      selectedOrders: [],
      orderItems: null,
      productsStock: null,
      assginmentTeamNameId: null,
    };
  },
  computed: {
    ...mapGetters(['getStoreManuDetails', 'getReservedProducts']),
    computedMaterials() {
      return this.getStoreManuDetails?.materialByOrderArticleResponseDtos?.map((item) => {
        return {
          ...item,
          isSelected: false,
        }
      })
    },
  },
  mounted() {

  },
  methods: {
    ...mapActions(['teamNames', 'assignTeamWithoutStock', 'assignProductsWithoutStock', 'loadDetailsShopManu']),
    toggleOrderItem(event, index) {
      const item = this.computedMaterials[index];
      const itemObject = {
        orderNumber: item.orderNumber,
        orderItemId: item.orderItemId,
        articleNumber: item.articleNumber,
      };

      if (event.target.checked) {
        this.selectedOrders.push(itemObject);
      } else {
        const itemIndex = this.selectedOrders.findIndex(
          (i) => { return i.orderNumber === item.orderNumber && i.orderItemId === item.orderItemId && i.articleNumber === item.articleNumber },
        );
        if (itemIndex !== -1) {
          this.selectedOrders.splice(itemIndex, 1);
        }
      }
      this.selectAll = this.selectedOrders.length === this.computedMaterials.length;
    },
    toggleSelectAll(event) {
      this.selectAll = event.target.checked;

      if (this.selectAll) {
        this.selectedOrders = this.computedMaterials.map((item) => {
          return {
            orderNumber: item.orderNumber,
            orderItemId: item.orderItemId,
            articleNumber: item.articleNumber,

          }
        });
      } else {
        this.selectedOrders = [];
      }
    },
    isItemChecked(item) {
      return this.selectedOrders.some(
        (i) => { return i.orderNumber === item.orderNumber && i.orderItemId === item.orderItemId },
      );
    },
    assignOneTeam(value) {
      this.assignTeamWithoutStock({
        object: value,
        successCallback: () => {
          this.loadDetailsShopManu({
            shipToManufactoryMovementId: this.orderMovementId,
          })
          this.assginmentTeamNameId = null
          this.selectedOrders = []
          this.selectAll = false
        },
      })
    },
    assignProducts(value) {
      this.assignProductsWithoutStock({
        object: value,
        successCallback: () => {
          this.loadDetailsShopManu({
            shipToManufactoryMovementId: this.orderMovementId,
          })
          this.assginmentTeamNameId = null
        },
      })
    },
    assignSelectedTeams(value) {
      this.assignTeamWithoutStock({
        object: value,
        successCallback: () => {
          this.loadDetailsShopManu({
            shipToManufactoryMovementId: this.orderMovementId,
          })
          this.assginmentTeamNameId = null
          this.selectedOrders = []
          this.selectAll = false
        },
      })
    },
    assignTeamOrder(value) {
      this.teamNames({
        startFilterPickUpDate: null,
        endFilterPickUpDate: null,
      })
      this.orderItems = {
        orderItemId: value.orderItemId,
        orderNumber: value.orderNumber,
      }
      this.assginmentTeamNameId = value.assginmentTeamNameId
    },
    assignTeamProducts(value) {
      this.teamNames({
        startFilterPickUpDate: null,
        endFilterPickUpDate: null,
      })
      this.productsStock = {
        productInStockId: value.productInStockId,
        articleNumber: value.articleNumber,
      }
      this.assginmentTeamNameId = value.assginmentTeamNameId
    },
    acceptOrder() {
      this.$emit('acceptOrder')
      this.onCancelToggle();
      this.$refs.modal.hide();
    },
    notAcceptedOrder() {
      this.$emit('notAccepted')
      this.onCancelToggle();
      this.$refs.modal.hide();
    },
    onCancel() {
      this.$emit('onCancelToggle');
      this.$refs.modal.hide();
    },
    onCancelToggle() {
      this.$emit('onCancelToggle');
    },

  },
};
</script>

<style scoped>
.team_table td {
  padding: 16px 9px !important;
}
.hover-row:hover {
  background-color: rgba(255, 39, 79, 0.1);
}
</style>
