<template>
  <div class="main">
    <b-tabs
      v-model="tabIndex"
      style="width: 100%"
    >
      <b-tab>
        <template #title>
          <strong style="font-weight: 500; color: black">{{ $t("OrderList") }}</strong>
        </template>
        <div style="padding-top: 20px">
          <InternalMoving
            @goTab2="goToTab2"
          />
        </div>
      </b-tab>
      <b-tab @click="callInPending">
        <template #title>
          <strong style="font-weight: 500; color: black">{{ $t("FactoryLog") }}</strong>
        </template>
        <div style="padding-top: 20px">
          <InPendingArticles />
        </div>
      </b-tab>
      <b-tab @click="goToTab3">
        <template #title>
          <strong style="font-weight: 500; color: black">{{ $t("ShipToManufactory") }}</strong>
        </template>
        <div style="padding-top: 20px">
          <ShippedArticles
            @dateShipped="dateShiped"
          />
        </div>
      </b-tab>
      <b-tab @click="callInStore">
        <template #title>
          <strong style="font-weight: 500; color: black">{{ $t("StoreLog") }}</strong>
        </template>
        <div style="padding-top: 20px">
          <ShippedToStore />
        </div>
      </b-tab>
      <b-tab @click="goToTab4">
        <template #title>
          <strong style="font-weight: 500; color: black">{{ $t("ShipToStore") }}</strong>
        </template>
        <div style="padding-top: 20px">
          <ShippedArticlesStore
           @dateShippedStore="dateShippedStores"
          />
        </div>
      </b-tab>
      <b-tab>
        <template #title>
          <strong style="font-weight: 500; color: black">{{ $t("DetailedReport") }}</strong>
        </template>
        <div style="padding-top: 20px">
          <DetailedReport />
        </div>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import InternalMoving from '@/views/InternalMoving.vue'
import InPendingArticles from '@/components/orders/InPendingArticles.vue'
import ShippedArticles from '@/components/orders/ShippedArticles.vue'
import ShippedArticlesStore from '@/components/orders/ShippedArticlesStore.vue'
import ShippedToStore from '@/components/orders/ShippedToStore.vue'
import DetailedReport from '@/components/orders/modals/DetailedReport.vue'

import { mapActions } from 'vuex'

export default {
  components: {
    InternalMoving,
    InPendingArticles,
    ShippedArticles,
    ShippedToStore,
    ShippedArticlesStore,
    DetailedReport,
  },
  data() {
    return {
      tabIndex: 0,
      deliverDateShipped: null,
      deliverDateShippedStores: null,
    }
  },
  computed: {

  },
  mounted() {
    this.orderItemStates()
    this.usersByMultipleRoles({
      status: true,
      object: ['Manager', 'TeamLeader'],
    })
  },
  methods: {
    ...mapActions(['filterItemMovement', 'usersByMultipleRoles', 'orderItemStates', 'filterOrderMovement', 'teamNames']),
    dateShiped(value) {
      this.deliverDateShipped = value
    },
    dateShippedStores(value) {
      this.deliverDateShippedStores = value
    },
    callInPending() {
      this.filterItemMovement({
        state: 'Pending',
        pageNumber: 1,
        pageSize: 15,
      })
      this.usersByMultipleRoles({
        status: true,
        object: ['Manager', 'TeamLeader'],
      })
    },
    callInStore() {
      this.teamNames({
        pageNumber: 1,
        pageSize: 15,
      })
    },
    goToTab2() {
      this.tabIndex = 1
      this.filterItemMovement({
        state: 'Pending',
        pageNumber: 1,
        pageSize: 15,
      })
      this.teamNames({
        pageNumber: 1,
        pageSize: 15,
      })
    },
    goToTab3() {
      this.filterOrderMovement({
        shipType: 'ShipOrderItemToManufactory',
        deliverDate: this.deliverDateShipped ? this.deliverDateShipped : null,
        pageNumber: 1,
        pageSize: 15,
      })
      this.teamNames({
        pageNumber: 1,
        pageSize: 15,
      })
    },
    goToTab4() {
      this.filterOrderMovement({
        shipType: 'ShipOrderItemToStore',
        deliverDate: this.deliverDateShippedStores ? this.deliverDateShippedStores : null,
        pageNumber: 1,
        pageSize: 15,
      })
      this.teamNames({
        pageNumber: 1,
        pageSize: 15,
      })
    },
  },
}
</script>

<style scoped>
.main{
  display: flex;
  justify-content: flex-start !important;
  gap: 10px;
}
@media screen and (min-width: 1200px) and (max-width: 1500px) {
  .main{
    padding: 90px 15px 15px 90px;
  }
}
@media screen and (max-width: 1200px) {
  .main{
    padding: 90px 15px 15px 20px;
  }
}
</style>
