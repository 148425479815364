<template>
  <div>
    <b-modal
      id="location-store"
      ref="modal"
      header-class="headerModal"
      centered
      size="md"
      :title="`${$t('Order')} : ${orderSelected?.orderNumber}`"
      hide-footer
      @close="onCancel"
    >
      <b-form>
        <b-form-group>
          <vue-select
            v-model="locationId"
            :options="getLocationsMoving"
            label="locationName"
            :placeholder="$t('Location')"
          />
        </b-form-group>
        <div class="buttonsEverywhere">
          <b-button
            type="button"
            variant="none"
            class="buttonSubmit"
            style="margin-left: 5px; background: #FF274F !important;"

            @click="onSubmit"
          >
            {{ $t('Submit') }}
          </b-button>
          <b-button
            type="button"
            variant="danger"
            class="buttonCancel"
            style="background: white; color: black; border-color: white;font-weight: 400;"

            @click="onCancel"
          >
            {{ $t('Cancel') }}
          </b-button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'


export default {
  props: ['orderSelected', 'locationIncluded'],
  data() {
    return {
      locationId: null,
    }
  },
  watch: {
    locationIncluded(value) {
      if (value.locationName && value.destinationLocationId) {
        this.locationId = {
          locationName: value.locationName,
          locationId: value.destinationLocationId,
        };
      } else {
        this.locationId = null
      }
    },
  },
  computed: {
    ...mapGetters(['getLocationsMoving']),
  },
  methods: {
    onSubmit() {
      this.$emit('addLocation', this.locationId)
      this.$refs.modal.hide()
      this.resetForm()
    },
    onCancel() {
      this.$refs.modal.hide()
      this.resetForm()
    },
    resetForm() {
      if (this.locationIncluded.locationName && this.locationIncluded.destinationLocationId) {
        this.locationId = {
          locationName: this.locationIncluded.locationName,
          locationId: this.locationIncluded.destinationLocationId,
        };
      } else {
        this.locationId = null
      }
    },
  },
}
</script>
