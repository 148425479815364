<template>
  <div>
    <b-sidebar
      id="ship-order"
      ref="modal"
      header-class="headerModalToggle"
      right
      shadow
      width="70%"
      :title="$t('MaterialVariants')"
      no-close-on-esc
      @close="onCancel"
    >
      <template #title>
        <strong style="color: #101828;">{{ $t('MaterialVariants') }}</strong>
      </template>
      <template
        v-slot:header-close
      >
        <button
          style="color: #98A2B3;padding-left: 0px; padding-right: 0px;height: 60px;"
          class="fa fa-close"
          @click="onCancel"
        />
      </template>
      <div style="display:flex;padding:10px 20px 10px 20px;gap:10px;">
        <b-form-group
          style="width: 30%"
          :label="$t('SenderLocation')"
        >
          <vue-select
            v-model="fromLocation"
            :options="getLocationsMoving"
            label="locationName"
            :reduce="(e) => e.locationId"
            :placeholder="$t('From')"
            style="width: 100%;"
          />
        </b-form-group>
        <b-form-group
          style="width: 30%"
          :label="$t('ReceiverLocation')"
        >
          <vue-select
            v-model="toLocation"
            :options="getLocationsMoving"
            label="locationName"
            :reduce="(e) => e.locationId"
            :placeholder="$t('To')"
            style="width: 100%;"
          />
        </b-form-group>
        <b-form-group
          style="width: 20%"
          :label="$t('SelectD')"
        >
          <date-picker
            v-model="deliverDate"
            value-type="format"
            :placeholder="$t('SelectD')"
            :format="'YYYY-MM-DD'"
            style="width: 100%;"
          />
        </b-form-group>
        <b-form-group
          style="width: 20%"
          :label="$t('AssignManager')"
        >
          <vue-select
            v-model="manager"
            :options="getUsersMultipleRoles"
            label="fullName"
            :reduce="(e) => e.userID"
            :placeholder="$t('AssignManager')"
            style="width: 100%;"
          />
        </b-form-group>
      </div>
      <div
        v-if="orderedMaterialsStore.length > 0"
        style="padding:20px;"
      >
        <div style="display:flex;justify-content: space-between;align-items: baseline;margin-bottom: 10px">
          <p style="font-weight: 600;margin:0px 0px 10px 0px">
            {{ $t('Orders') }}
          </p>
        </div>
        <table
          class="team_table"
        >
          <thead>
            <tr>
              <th>
                {{ $t('Order') }}
              </th>
              <th>
                {{ $t('ArticleName') }}
              </th>
              <th>
                {{ $t('Department') }}
              </th>
              <th>
                {{ $t('State') }}
              </th>
              <th>
                {{ $t('Remove') }}
              </th>
            </tr>
          </thead>
          <tbody
            v-for="(item,index) in orderedMaterialsStore"
            :key="index"
          >
            <tr>
              <td>
                {{ item.orderNumber }}
              </td>
              <td>
                {{ item.articleNumber }}
              </td>
              <td>
                <button
                  v-b-modal.location-store
                  class="button"
                  style="width:250px !important;"
                  @click="callLocation(item, index)"
                >
                  {{ item.locationName ? item.locationName : $t('Department') }}
                </button>
              </td>
              <td>
                <button
                  class="button"
                  style="width:250px !important;"
                >
                  {{ $t(item.currentState) }}
                </button>
              </td>
              <td>
                <button
                  class="button"
                  style="width:150px !important;"
                  @click="removeOrder(index)"
                >
                  {{ $t('Remove') }}
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        v-else
        style="padding:20px;"
      >
        <p style="color:red;">
          {{ $t('No order selected') }}
        </p>
      </div>
      <div
        v-if="reservedProducts.length > 0"
        style="padding:20px;"
      >
        <div style="display:flex;justify-content: space-between;align-items: baseline;margin-bottom: 10px">
          <p style="font-weight: 600;margin:0px 0px 10px 0px">
            {{ $t('Articles') }}
          </p>
        </div>
        <table
          class="team_table"
        >
          <thead>
            <tr>
              <th>
                {{ $t('ArticleNumber') }}
              </th>
              <th>
                {{ $t('Notes') }}
              </th>
              <th>
                {{ $t('Amount') }}
              </th>
              <th>
                {{ $t('Department') }}
              </th>
              <th>
                {{ $t('Remove') }}
              </th>
            </tr>
          </thead>
          <tbody
            v-for="(item,index) in reservedProducts"
            :key="index"
          >
            <tr>
              <td>
                {{ item.articleNumber }}
              </td>
              <td>
                <b-form-input
                  v-model="item.notes"
                  :placeholder="$t('Write')"
                />
              </td>
              <td>
                <b-form-input
                  v-model.number="item.amount"
                  type="number"
                  :placeholder="$t('Number')"
                />
              </td>
              <td>
                <button
                  v-b-modal.article-store
                  class="button"
                  style="width:250px !important;"
                  @click="callLocationArticle(item, index)"
                >
                  {{ item.locationName ? item.locationName : $t('Department') }}
                </button>
              </td>
              <td>
                <button
                  class="button"
                  style="width:150px !important;"
                  @click="removeArticle(index)"
                >
                  {{ $t('Remove') }}
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        v-else
        style="padding:20px;"
      >
        <p style="color:red;">
          {{ $t('No articles selected') }}
        </p>
      </div>
      <div
        v-if="removedOrders.length > 0"
        style="padding:20px;"
      >
        <div style="display:flex;justify-content: space-between;align-items: baseline;margin-bottom: 10px">
          <p style="font-weight: 600;margin:0px 0px 10px 0px">
            {{ $t('RemovedOrdersShow') }}
          </p>
        </div>
        <table
          class="team_table"
        >
          <thead>
            <tr>
              <th>
                {{ $t('Order') }}
              </th>
              <th>
                {{ $t('ArticleName') }}
              </th>
              <th>
                {{ $t('Return') }}
              </th>
            </tr>
          </thead>
          <tbody
            v-for="(item,index) in removedOrders"
            :key="index"
          >
            <tr>
              <td>
                {{ item.orderNumber }}
              </td>
              <td>
                {{ item.articleNumber }}
              </td>
              <td>
                <button
                  class="button"
                  style="width:150px !important;"
                  @click="returnOrders(index)"
                >
                  {{ $t('Return') }}
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        v-if="removedProducts.length > 0"
        style="padding:20px;"
      >
        <div style="display:flex;justify-content: space-between;align-items: baseline;margin-bottom: 10px">
          <p style="font-weight: 600;margin:0px 0px 10px 0px">
            {{ $t('RemovedArticlesShow') }}
          </p>
        </div>
        <table
          class="team_table"
        >
          <thead>
            <tr>
              <th>
                {{ $t('ArticleNumber') }}
              </th>
              <th>
                {{ $t('Notes') }}
              </th>
              <th>
                {{ $t('Amount') }}
              </th>
              <th>
                {{ $t('Return') }}
              </th>
            </tr>
          </thead>
          <tbody
            v-for="(item,index) in removedProducts"
            :key="index"
          >
            <tr>
              <td>
                {{ item.articleNumber }}
              </td>
              <td>
                <b-form-input
                  v-model="item.notes"
                  :placeholder="$t('Write')"
                  disabled
                />
              </td>
              <td>
                <b-form-input
                  v-model.number="item.amount"
                  type="number"
                  :placeholder="$t('Number')"
                  disabled
                />
              </td>
              <td>
                <button
                  class="button"
                  style="width:150px !important;"
                  @click="returnArticle(index)"
                >
                  {{ $t('Return') }}
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        class="buttonsEverywhere"
        style="padding:10px 20px 10px 20px"
      >
        <b-button
          type="button"
          variant="none"
          class="buttonSubmit"
          style="margin-left: 5px; background: #FF274F !important;"
          :disabled="isButtonDisabled"
          @click="onSubmit"
        >
          {{ $t('Submit') }}
        </b-button>
        <b-button
          type="button"
          variant="danger"
          class="buttonCancel"
          style="background: white; color: black; border-color: white;font-weight: 400;"

          @click="onCancel"
        >
          {{ $t('Cancel') }}
        </b-button>
      </div>
      <LocationForOrderStore
        :location-included="locationIncluded"
        :order-selected="orderSelected"
        @addLocation="addLocationTo"
      />
      <LocationForArticleStore
        :location-article-included="locationArticleIncluded"
        :article-selected="articleSelected"
        @addLocationArticle="addLocationArticleTo"
      />
    </b-sidebar>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import DatePicker from 'vue2-datepicker';
import LocationForOrderStore from './LocationForOrderStore.vue';
import LocationForArticleStore from './LocationForArticleStore.vue';
import 'vue2-datepicker/index.css';




export default {
  components: {
    LocationForOrderStore,
    LocationForArticleStore,
    DatePicker,
  },
  // eslint-disable-next-line vue/require-prop-types
  props: [],
  data() {
    return {
      fromLocation: null,
      toLocation: null,
      orderSelected: null,
      orderIndex: null,
      locationIncluded: null,
      locationArticleIncluded: null,
      articleIndex: null,
      articleSelected: null,
      deliverDate: null,
      manager: null,
      removedProducts: [],
      removedOrders: [],
    };
  },
  computed: {
    ...mapGetters(['getOrderMaterials', 'getUsersMultipleRoles', 'getReservedProducts', 'getLocationsMoving']),
    orderedMaterialsStore() {
      return this.getOrderMaterials.map((item) => {
        return {
          ...item,
          destinationLocationId: null,
          locationName: null,
          notes: null,
        }
      })
    },
    reservedProducts() {
      return this.getReservedProducts.map((item) => {
        return {
          ...item,
          notes: null,
          destinationLocationId: null,
          locationName: null,
          amount: 0,
        }
      })
    },
    isButtonDisabled() {
      return (
        !this.manager
        || !this.fromLocation
        || !this.toLocation
        || !this.deliverDate
        || (this.orderedMaterialsStore.length === 0 && this.reservedProducts.length === 0)
      );
    },
  },
  watch: {
    fromLocation(value) {
      if (value) {
        this.loadReservedProducts({
          locationId: value,
        })
        this.removedProducts = []
      } else {
        this.resetLoadReservedProducts()
        this.removedProducts = []
      }
    },
  },
  methods: {
    ...mapActions(['movingLocations', 'orderedMaterials', 'usersByMultipleRoles', 'shipAllOrderToStore', 'resetLoadReservedProducts', 'loadReservedProducts']),
    returnArticle(index) {
      const removedItem = this.removedProducts.splice(index, 1)[0];
      if (removedItem) {
        const removed = {
          ...removedItem,
          notes: null,
          destinationLocationId: null,
          locationName: null,
          amount: 0,
        }
        this.getReservedProducts.push(removed);
      }
    },
    removeArticle(index) {
      const removedItem = this.getReservedProducts.splice(index, 1)[0];
      if (removedItem) {
        const removed = {
          ...removedItem,
          notes: null,
          destinationLocationId: null,
          locationName: null,
          amount: 0,
        }
        this.removedProducts.push(removed);
      }
    },
    removeOrder(index) {
      const removedItem = this.getOrderMaterials.splice(index, 1)[0];
      if (removedItem) {
        const removed = {
          ...removedItem,
          destinationLocationId: null,
          locationName: null,
          notes: null,
        }
        this.removedOrders.push(removed);
      }
    },
    returnOrders(index) {
      const removedItem = this.removedOrders.splice(index, 1)[0];
      if (removedItem) {
        const removed = {
          ...removedItem,
          destinationLocationId: null,
          locationName: null,
          notes: null,
        }
        this.getOrderMaterials.push(removed);
      }
    },
    callLocation(value, index) {
      this.orderSelected = value
      this.orderIndex = index
      this.locationIncluded = value
      this.movingLocations()
    },
    callLocationArticle(value, index) {
      this.articleSelected = value
      this.articleIndex = index
      this.locationArticleIncluded = value
      this.movingLocations()
    },
    addLocationTo(value) {
      if (value !== null) {
        this.$set(this.orderedMaterialsStore[this.orderIndex], 'destinationLocationId', value.locationId);
        this.$set(this.orderedMaterialsStore[this.orderIndex], 'locationName', value.locationName);
      } else {
        this.$set(this.orderedMaterialsStore[this.orderIndex], 'destinationLocationId', null);
        this.$set(this.orderedMaterialsStore[this.orderIndex], 'locationName', null);
      }
    },
    addLocationArticleTo(value) {
      if (value !== null) {
        this.$set(this.reservedProducts[this.articleIndex], 'destinationLocationId', value.locationId);
        this.$set(this.reservedProducts[this.articleIndex], 'locationName', value.locationName);
      } else {
        this.$set(this.reservedProducts[this.articleIndex], 'destinationLocationId', null);
        this.$set(this.reservedProducts[this.articleIndex], 'locationName', null);
      }
    },
    onSubmit() {
      const objecti = {
        deliverDate: this.deliverDate,
        deliverLocationId: this.fromLocation,
        receiverLocationId: this.toLocation,
        userId: this.manager,
        orderItems: this.orderedMaterialsStore.map((item) => {
          return {
            destinationLocationId: item.destinationLocationId,
            orderItemId: item.orderItemId,
            notes: null,
          }
        }),
        additionalMaterialVariantsInMovement: [],
        productInStockWithNotes: this.reservedProducts.map((item) => {
          return {
            productInStockId: item.productInStockId,
            amount: item.amount,
            notes: item.notes,
            dedicatedLocationId: item.destinationLocationId,
          }
        }),
      }
      this.$emit('shipAllOrder', objecti)
      this.$emit('onCancelToggle');
      this.$refs.modal.hide()
      this.resetForm()
    },
    onCancel() {
      this.$emit('onCancelToggle');
      this.$refs.modal.hide();
      this.resetForm()
    },
    resetForm() {
      this.resetLoadReservedProducts()
      this.fromLocation = null
      this.toLocation = null
      this.manager = null
      this.deliverDate = null
    },
  },
};
</script>

<style scoped>
.team_table td {
  padding: 16px 9px !important;
}
.hover-row:hover {
  background-color: rgba(255, 39, 79, 0.1);
}
</style>
